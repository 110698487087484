import React from "react";
import { useMediaQuery } from "react-responsive";

import FooterIntiva from "../components/FooterIntiva";
import FormContact from "../components/FormContact";
import TextContact from "../components/TextContact";

export default function Contact() {
	const hp = useMediaQuery({
		maxWidth: 599,
	});
	const tab = useMediaQuery({
		minWidth: 600,
		maxWidth: 991,
	});

	return (
		<div className="w-100" id="contact">
			<div className="container bottom-form pt-5">
				<div
					className={`row rows-cols-2 ${hp && "flex-column"} ${
						tab && "flex-column"
					}`}
				>
					<TextContact hp={hp} tab={tab} />
					<FormContact hp={hp} tab={tab} />
				</div>
			</div>
			<FooterIntiva hp={hp} tab={tab} />
		</div>
	);
}
