import { useState } from "react";
import { useMediaQuery } from "react-responsive";
import { AnimatePresence } from "framer-motion";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Modals from "./ModalNavbar/Modals";
import brand from "../assets/image/logo-landing.png";
import menu from "../assets/image/menu.png";

export default function NavbarIntiva() {
	const notLg = useMediaQuery({
		query: "(max-width: 991px)",
	});

	const [modalOpen, setModalOpen] = useState(false)
	const close = () => setModalOpen(false)
	const open = () => setModalOpen(true)

	return (
		<nav className="navbar navbar-expand-lg navbar-dark bg-transparent">
			<div className={`${notLg && "navbar-brand-responsive"}`}>
				<a className="ps-5" href="/">
					<LazyLoadImage src={brand} alt="Intiva" width="80%" className="mt-4"/>
				</a>
			</div>
			<div className="container justify-end-responsive mt-4 pe-5 ">
				<div className="flex-responsive button-nav-responsive">
					<div className="navbar-menu-responsive" onClick={() => (modalOpen ? close() : open())}>
						<LazyLoadImage src={menu} alt="" />
					</div>
				</div>
			</div>
			<AnimatePresence
				initial={false}
				exitBeforeEnter={true}
				onExitComplete={() => null}
			>
				{modalOpen && <Modals handleClose={close}/>}
			</AnimatePresence>
		</nav>
	);
}
