export default function ContactFooter() {
	return (
		<div className="p-0 text-white">
			<h5 className="fm-bold fs-5 mb-2">PT. Jaya Pirata Dinamika</h5>
			<p className="fm-medium fs-6 mb-4">
				JI. Tebet Barat Dalam Raya No.31, RT.7/RW.3. Tebet Bar., Kec. Tebet,
				Kota Jakarta Selatan, DKI Jakarta 12810
			</p>
			<h5 className="fm-bold fs-5 mb-2">office@intiva.id</h5>
			<p className="fm-medium fs-6">02121383852</p>
		</div>
	);
}
