export default function TextContact({ hp, tab }) {
  return (
    <div className="col pt-5 text-white">
      {hp ? (
        <div className="px-3">
          <h1 className="fw-bold">Ready to launch a</h1>
          <h1 className="color-yellow fw-bold">quality product</h1>
          <h1 className="fw-bold">with us?</h1>
        </div>
      ) : (
        <div className="frhd-bold">
          <h1 className="fw-bold">Ready to launch a</h1>
          <h1 className="color-yellow fw-bold">quality product</h1>
          <h1 className="fw-bold">with us?</h1>
        </div>
      )}

      <div
        className={`fm-regular color-paragraph fs-6 mt-4 w-80 ${hp && "mb-5"} ${
          tab && "mb-5"
        }`}
      >
        {hp ? (
          <p style={{ fontSize: 12 }} className="ps-3">
            Contact us and our service will get back to you a maximum of 1x24
            hours a working day.
          </p>
        ) : (
          <p>
            Contact us and our service will get back to you a maximum of 1x24
            hours a working day.
          </p>
        )}
      </div>
    </div>
  );
}
