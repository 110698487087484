import mobile from '../assets/image/product/MobileA.png'
import web from '../assets/image/product/WebA.png'
import sofware from '../assets/image/product/SoftwareC.png'
import bigData from '../assets/image/product/BigD.png'
import machine from '../assets/image/product/machineL.png'
import enterprise from '../assets/image/product/Enterprise.png'

const dataProducts = [
    {
        id: 0,
        icon: mobile,
        title: "Mobile App",
        desc: "Mobile-based software that can run on Android and iOS devices, which is lightweight and adds services to the services you offer."
    },
    {
        id: 1,
        icon: web,
        title: "Web App",
        desc: "Web-based software that is easily accessible on various devices using a browser, which requires absolutely no installation on a computer."
    },
    {
        id: 2,
        icon: sofware,
        title: "Software Consulting",
        desc: "Massive and scalable data management that can translate into computational analytics, data visualization, and artificial intelligence."
    },
    {
        id: 3,
        icon: bigData,
        title: "Big Data",
        desc: "Massive and scalable data management that can translate into computational analytics, data visualization, and artificial intelligence."
    },
    {
        id: 4,
        icon: enterprise,
        title: "Enterprise System",
        desc: "Software clusters covering various business roles (such as ERP, SCM, CRM, etc.) that are integrated into one organization."
    },
    {
        id: 5,
        icon: machine,
        title: "Machine Learning",
        desc: "Artificial intelligence combined with neural network technology, which produces smart technology that can learn a set of data patterns."
    },
]

export default dataProducts;