import logo from "../assets/image/intiva-logo.png";
import brand from "../assets/image/intiva-brand.png";

export default function LogoFooter() {
	return (
		<div className="p-0">
			<div className="d-flex justify-content-center pt-3">
				<img src={logo} alt={logo} className="logo-footer" />
				<img src={brand} alt={brand} className="brand-footer" />
			</div>
		</div>
	);
}
