import React from "react";
import ReactDOM from "react-dom/client";

import App from "./App";
import "./index.css";

// React Slick
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// Bootstrap
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/js/bootstrap.bundle.min.js";

// Fonts Manrope
import "./fonts/Manrope/bold.otf";
import "./fonts/Manrope/medium.otf";
import "./fonts/Manrope/regular.otf";

// Fonts RedHatDisplay
import "./fonts/RedHatDisplay/RedHatDisplay-Black.otf";
import "./fonts/RedHatDisplay/RedHatDisplay-Bold.otf";
import "./fonts/RedHatDisplay/RedHatDisplay-Medium.otf";
import "./fonts/RedHatDisplay/RedHatDisplay-Regular.otf";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>
);
