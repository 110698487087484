// import SMM from "../assets/image/experience/SMM.png"
// import SPO from "../assets/image/experience/SPO.png"
// import KLH from "../assets/image/experience/KLH.png"
// import ORP from "../assets/image/experience/ORM.png"
// import Tampos from "../assets/image/experience/Tampos.png"
// import Golan from "../assets/image/experience/Golan.png"

// import SMM2 from "../assets/image/experience/SMM2.png"
// import SPO2 from "../assets/image/experience/SPO2.png"
// import ORP2 from "../assets/image/experience/ORM2.png"
// import KLH2 from "../assets/image/experience/KLH2.png"
// import Tampos2 from "../assets/image/experience/Tampos2.png"
// import Golan2 from "../assets/image/experience/Golan2.png"

import SMM3 from "../assets/image/modalExperience/SMM.png"
import SPO3 from "../assets/image/modalExperience/SPO.png"
import ORP3 from "../assets/image/modalExperience/ORP.png"
import KLN from "../assets/image/modalExperience/KLN.png"
import Tampos3 from "../assets/image/modalExperience/Tampos.png"
import Golan3 from "../assets/image/modalExperience/Golan.png"

const dataModalExperience = [
    {
        id: 1,
        photo: SMM3,
        title: "Social Media Monitoring",
        desc: "SMM is a system for measuring the success of social media marketing strategies used by companies or brands"
    },
    {
        id: 2,
        photo: SPO3,
        title: "Sistem Pelaporan Online (Baintelkam Polri)",
        desc: "This application can report online via smartphones and websites to facilitate data processing so that it can implement policies that will be taken in the future. This application presents data in the form of tables and graphs as well as direct data input"
    },
    {
        id: 3,
        photo: KLN,
        title: "SI Biro KLN Kementrian Lingkungan Hidup",
        desc: "SI BIRO KLN is an official company website to provide important information to the audience about the scope of work of the  BIRO KLN and the Indonesian Ministry of Environment"
    },
    {
        id: 4,
        photo: ORP3,
        title: "Object Recognition Project",
        desc: "Object Recognition is a computer technology related to computer vision and image processing related to semantic object detection"
    },
    {
        id: 5,
        photo: Tampos3,
        title: "Tampos",
        desc: "Tampos is a web application-based cooperative platform used by Toyota Astra Motor Indonesia. This application has been used by all Toyota Astra Motor Cooperatives and more than 1400 users"
    },
    {
        id: 6,
        photo: Golan3,
        title: "Golancorp",
        desc: "Golancorp is a web-based platform and a mobile application that is the meeting place suppliers and sellers. This system is used by one of the largest Mitsubishi Group Dealers in Indonesia. This application has been used by more than 10,000 users."
    }
]

export default dataModalExperience