import React, { useState } from "react";
import { HiArrowNarrowRight } from "react-icons/hi";
import { motion } from "framer-motion";
import { useMediaQuery } from "react-responsive";

// Assets
import assets1 from "../assets/image/assets-1.png";
import assets2 from "../assets/image/assets-2.png";

export default function FormContact({hp, tab}) {
	const [form, setForm] = useState({
		name: "",
		email: "",
		phone: "",
		message: "",
	});

	return (
		<motion.div className="col position-relative">
			<div className="form px-5 py-3">
				<div className="container fm-regular">
					<div className="d-flex form-divide">
						<div className="row mt-3 mb-3 input-divide">
							<div className="form__group field col">
								<input
									required
									placeholder="Nama"
									className="form__field"
									type="text"
									value={form.name}
									onChange={(e) =>
										setForm({
											...form,
											name: e.target.value,
										})
									}
								/>
								<label className="form__label" htmlFor="name">
									Your Name
								</label>
							</div>
						</div>
						<div className="row mt-3 mb-3 input-divide">
							<div className="form__group field col">
								<input
									required
									placeholder="Email"
									className="form__field"
									type="email"
									value={form.email}
									onChange={(e) =>
										setForm({
											...form,
											email: e.target.value,
										})
									}
								/>
								<label className="form__label" htmlFor="email">
									Your Email
								</label>
							</div>
						</div>
						<div className="row mt-3 mb-3 input-divide">
							<div className="form__group field col">
								<input
									required
									placeholder="No Hp"
									className="form__field"
									type="tel"
									value={form.phone}
									onChange={(e) =>
										setForm({
											...form,
											phone: e.target.value,
										})
									}
								/>
								<label className="form__label" htmlFor="handphone">
									Phone Number
								</label>
							</div>
						</div>
						<div className="row mt-3 mb-3 input-divide">
							<div className="form__group field col">
								<select 
									className="form__field option" 
									aria-label="Default select example"
									onChange=""
									required>
										<option value="none" selected>Country</option>
								</select>
							</div>
						</div>
						<div className="row mt-3 mb-3 input-divide">
							<div className="form__group field col">
								<select 
									className="form__field option" 
									aria-label="Default select example"
									onChange=""
									required>
										<option value="none" selected>Company Name</option>
								</select>
							</div>
						</div>
						<div className="row mt-3 mb-3 input-divide">
							<div className="form__group field col">
								<select 
									className="form__field option" 
									aria-label="Default select example"
									onChange=""
									required>
										<option value="none" selected>Interested In</option>
								</select>
							</div>
						</div>
					</div>
					<div className="row mt-3 mb-3">
						<div className="form__group field col">
							<input
								required
								placeholder="Pesan"
								className="form__field"
								type="text"
								value={form.message}
								onChange={(e) =>
									setForm({
										...form,
										message: e.target.value,
									})
								}
							/>
							<label className="form__label" htmlFor="message">
								Message
							</label>
						</div>
					</div>
					<div className={`d-flex ${hp && "justify-content-center"} ${tab && "justify-content-center"}`}>
						<button
							type="submit"
							className="btn-yellow-contact px-3 py-2 mt-4 mb-3 btn-contact"
						>
							<span className="me-2 frhd-bold">Contact Us</span>{" "}
							<HiArrowNarrowRight size="1.3em" />
						</button>
					</div>
				</div>
			</div>
			<motion.img
				className="assets-1"
				src={assets1}
				alt="."
				animate={{ x: [0, -360, 0, -360], y: [0, 0, -240, -240] }}
				transition={{
					repeat: Infinity,
					repeatType: "reverse",
					duration: 30,
				}}
			/>
			<motion.img
				className="assets-2"
				src={assets2}
				alt="."
				animate={{ x: [0, 360, 0, 360], y: [0, 0, 240, 240] }}
				transition={{
					repeat: Infinity,
					repeatType: "reverse",
					duration: 30,
				}}
			/>
		</motion.div>
	);
}
