import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { BiArrowBack } from 'react-icons/bi'
import { LazyLoadImage } from "react-lazy-load-image-component";
import { motion } from 'framer-motion';
import IconScroll from '../../utils/IconScroll';

// import data dummy
import dataModalExperience from '../../data/modalExperience';

const titleAnimate = {
    offscreen: { y: 0, opacity: 0 },
    onscreen: {
        y: 0,
        opacity: 1,
        transition: {
            type: "spring",
            bounce: 0,
            duration: 2,
        },
    },
};

const cardsAnimate = {
    offscreen: { y: 0, opacity: 0 },
    onscreen: {
        y: 0,
        opacity: 1,
        transition: {
            type: "spring",
            bounce: 0,
            duration: 2,
        },
    },
};



export default function ButtonShowProject() {
    const [show, setShow] = useState(false);
    const [fullscreen] = useState(true);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    // const displayScrollIcon = () => {
    //     window.onscroll = function(ev) {
    //         if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
    //             document.getElementById('cobaScroll').style.display = 'none'
    //         }else{
    //             document.getElementById('cobaScroll').style.display = 'inline-block'
    //         }
    //     };
    // }
    
    return (
        <>
            <button className="button btn-showall" onClick={handleShow}>
                <span className="button_lg">
                    <span className="button_sl"></span>
                    <span className="button_text">View More</span>
                </span>
            </button>

            <Modal show={show} fullscreen={fullscreen} onHide={handleClose}>
                <Modal.Body className='modal-body'>
                    {/* <div 
                        className='d-flex justify-content-end position-fixed'
                        id='cobaScroll' 
                        style={{left: '95%', top: '50%'}}
                        onScroll={displayScrollIcon}
                    >
                        <IconScroll/>
                    </div> */}
                    <motion.div
                        initial="offscreen"
                        whileInView="onscreen"
                        viewport={{ once: true }}
                        transition={{ staggerChildren: 0.2 }}
                    >
                    <div onClick={handleClose} className="pt-5 pb-3 top-modal-arrow" style={{width: 200, color: '#FFC107'}}>
                        <p className="back-button-modal p-2">
                            <BiArrowBack className="me-2"/>
                            Back
                        </p> 
                    </div>
                    <motion.div 
                        className="top-modal-experience-wrap"
                        variants={titleAnimate}
                    >
                        <p 
                            className="fw-bold p-2 text-white" 
                            style={{ fontFamily: 'Red Hat Display' }}>
                                Our <span style={{ color: '#FFC107' }}>Project</span>
                        </p>
                    </motion.div>
                    <div className="d-flex flex-column base-modal-content container mb-4">
                        {dataModalExperience.map(data => {
                            return data.id % 2 === 0 ? 
                            <motion.div 
                                key={data.id}
                                className={`d-flex modal-content-wrap-${data.id}`}
                                variants={cardsAnimate}
                            >
                                <div>
                                    <h1 className="mb-4 fw-bold lh-sm" style={{fontFamily: 'Red Hat Display'}}>
                                        {data.title}
                                    </h1>
                                    <p 
                                        className="lh-sm"
                                        style={{ fontFamily: 'Manrope', color: 'rgba(255, 255, 255, 0.75)'}}
                                    >
                                        {data.desc}
                                    </p>
                                </div>
                                <div>
                                    <LazyLoadImage src={data.photo} alt="" />
                                </div>
                            </motion.div>
                            :
                            <motion.div 
                                key={data.id}
                                className="d-flex modal-content-wrap" 
                                variants={cardsAnimate}
                            >
                                <div>
                                    <LazyLoadImage src={data.photo} alt="" />
                                </div>
                                <div>
                                    <h1 className="mb-4 fw-bold lh-sm" style={{fontFamily: 'Red Hat Display'}}>
                                        {data.title}
                                    </h1>
                                    <p 
                                        className="lh-sm"
                                        style={{ fontFamily: 'Manrope', color: 'rgba(255, 255, 255, 0.75)'}}
                                    >
                                        {data.desc}
                                    </p>
                                </div>
                            </motion.div>
                        })}
                    </div>
                    <div onClick={handleClose} className="top-modal-arrow" style={{width: 200, color: '#FFC107'}}>
                        <p className="back-button-modal p-2">
                            <BiArrowBack className="me-2"/>
                            Back
                        </p> 
                    </div>
                    </motion.div>
                </Modal.Body>
            </Modal>
        </>
    );
}