import ContactFooter from "./ContactFooter";
import LogoFooter from "./LogoFooter";

export default function FooterIntiva({ hp, tab }) {
	return (
		<div
			style={{ borderTop: "1px solid #404444", padding: "5rem 0" }}
			className={`bg-transparent ${tab && "d-flex justify-content-center"}`}
		>
			<div
				className={`row w-100 row-cols-2 text-white ${
					hp && "flex-column m-0"
				} ${tab && "flex-column m-0 align-items-center"}`}
			>
				<div
					className={`col-4 ${
						hp && "d-flex w-100 justify-content-center mb-5"
					} ${tab && "d-flex w-100 justify-content-center mb-5"}`}
				>
					<LogoFooter />
				</div>
				<div
					className={`col-8 ${hp && "w-100 text-center"} ${
						tab && "w-75 text-center"
					}`}
				>
					<ContactFooter />
				</div>
			</div>
		</div>
	);
}
