import { motion } from 'framer-motion';

// import data dummy
import experience from '../data/experience';

const experienceAnimate = {
	offscreen: { y: 0, opacity: 0 },
	onscreen: {
		y: 0,
		opacity: 1,
		transition: {
			type: "spring",
			bounce: 0.3,
			duration: 2,
		},
	},
};

export default function CardSliderExperience() {
	return (
        <motion.div 
            className="d-flex flex-column base-content-experience"
            initial="offscreen"
			whileInView="onscreen"
			viewport={{ once: true }}
			transition={{ staggerChildren: 0.2 }}
        >
            {experience.map(data => {
                return data.id % 2 === 0 ?
                <motion.div 
                    key={data.id}
                    className={`d-flex content-experience-wrap-${data.id}`} 
                    variants={experienceAnimate}
                >
                    <div className='d-flex flex-column content-experience-text' style={{margin: 'auto'}}>
                        <p 
                            className='text-uppercase color-yellow experience-text-porto' 
                            style={{fontFamily: 'Manrope', color: 'rgba(255, 193, 7, 1)'}}
                        >
                            Our Fortofolio
                        </p>
                        <p className='experience-text-title fw-bold' style={{fontFamily: 'Red Hat Display'}}>
                            {data.title}
                        </p>
                        <p className='experience-text-desc' style={{fontFamily: 'Manrope'}}>
                            {data.desc}
                        </p>
                    </div>
                    <img src={data.photo} alt={data.title} className='content-experience-image'/>
                </motion.div>
                :
                <motion.div 
                    key={data.id}
                    className="d-flex content-experience-wrap d-flex"
                    variants={experienceAnimate}
                >
                    <img src={data.photo} alt={data.title} className='content-experience-image'/>
                    <div className='d-flex flex-column content-experience-text' style={{margin: 'auto'}}>
                        <p 
                            className='text-uppercase color-yellow experience-text-porto' 
                            style={{fontFamily: 'Manrope', color: 'rgba(255, 193, 7, 1)'}}
                        >
                            Our Fortofolio
                        </p>
                        <p className='experience-text-title fw-bold' style={{fontFamily: 'Red Hat Display'}}>
                            {data.title}
                        </p>
                        <p className='experience-text-desc' style={{fontFamily: 'Manrope'}}>
                            {data.desc}
                        </p>
                    </div>
                </motion.div>
            })}
        </motion.div>
	);
}
