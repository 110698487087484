import React from "react";
import { motion } from "framer-motion";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-scroll";
import asset from "../assets/image/assets-3.png";
import NavbarIntiva from "../components/NavbarIntiva";

const textLandingAnimate = {
	offscreen: { y: 100, opacity: 0 },
	onscreen: {
		y: 0,
		opacity: 1,
		transition: {
			type: "spring",
			bounce: 0.3,
			duration: 3,
		},
	},
};

export default function Landing() {
	const hp = useMediaQuery({
		maxWidth: 599,
	});
	const notLg = useMediaQuery({
		maxWidth: 991,
	});

	return (
		<div>
			<NavbarIntiva />
			<div className="px-5 w-100 landing-bottom-responsive">
				<div
					className={`h-100 d-flex padding-top-landing ${
						notLg ? "flex-column" : ""
					} position-relative`}
				>
					<div
						className={`d-flex h-50 flex-column ${
							notLg && "order-2"
						} text-landing-responsive`}
					>
						<motion.div
							className={`text-white mb-responsive-landing`}
							initial="offscreen"
							whileInView="onscreen"
							viewport={{ once: true }}
							transition={{ staggerChildren: 0.5 }}
						>
							<motion.div
								className={`${notLg ? "mb-5" : "mb-4"} frhd-bold ${
									notLg && "text-center"
								}`}
								variants={textLandingAnimate}
							>
								{hp ? <h1 className="m-0 title-hero fw-bold" style={{fontSize: 30}} >Best Partner</h1>
								: <h1 className="m-0 title-hero fw-bold" style={{fontSize: 50}} >Best Partner</h1>}
								
								{hp ? <h1 className="m-0 animate-text fw-bold" style={{fontSize: 30}} >your digital solution</h1>
								: <h1 className="m-0 animate-text fw-bold" style={{fontSize: 50}} >your digital solution</h1>}

							</motion.div>
							<motion.div
								className={`fm-regular fs-6 ${notLg && "text-center"}`}
								style={{ color: "#BFBFBF" }}
								variants={textLandingAnimate}
							>
								<p className="m-0" style={{fontSize: 20}}>
									Together with Intiva, we will help you turn your brilliant
								</p>
								<p className="m-0" style={{fontSize: 20}}>
									ideas into reliable and high-quality software.
								</p>
							</motion.div>
						</motion.div>
						<div className={`${notLg && "text-center"}`}>
							<Link
								aria-current="page"
								to="contact"
								spy={true}
								smooth={true}
								duration={200}
							>
								<div>
								<button className="btn-yellow btn-landing-responsive color-gray">
									<div className="svg-wrapper-1">
										<div className="svg-wrapper" style={{margin: 'auto'}}>
											<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="30" height="30">
												<path fill="none" d="M0 0h24v24H0z"></path>
												<path fill="currentColor" d="M1.946 9.315c-.522-.174-.527-.455.01-.634l19.087-6.362c.529-.176.832.12.684.638l-5.454 19.086c-.15.529-.455.547-.679.045L12 14l6-8-8 6-8.054-2.685z"></path>
											</svg>
										</div>
									</div>
									<span className="fw-bolder">Contact Us</span>
								</button>
								</div>
							</Link>
						</div>
					</div>
					<div className={`${notLg && "order-1 pt-img-landing-responsive"}`}>
						<motion.img
							src={asset}
							alt="."
							className="image-landing"
							animate={{ y: [0, -20] }}
							transition={{
								repeat: Infinity,
								repeatType: "reverse",
								duration: 3,
							}}
						/>
					</div>
				</div>
			</div>
		</div>
	);
}
