import ceo from "../assets/image/modalLeader/megandi2.svg";
import coo from "../assets/image/modalLeader/adhytia2.svg";
import dm from "../assets/image/modalLeader/sahrul2.svg";
import sdm from "../assets/image/modalLeader/fathan2.svg";
import gam from "../assets/image/modalLeader/elfin2.svg";

const leader = [
  {
    id: 1,
    photo: ceo,
    nama: "Megandi",
    position: "Chief Executive Officer",
    linkedin: "https://www.linkedin.com/in/megandi-%E2%80%8E-65b958146/",
  },
  {
    id: 2,
    photo: coo,
    nama: "Adhytia Ihza M",
    position: "Chief Product Officer",
    linkedin: "https://www.linkedin.com/in/adhytia-ihza-mahendra-02515a160/",
  },
  {
    id: 3,
    photo: dm,
    nama: "Sahrul",
    position: "Chief Technology Officer",
    linkedin: "https://www.linkedin.com/in/sahrulsukardi/",
  },
  {
    id: 4,
    photo: sdm,
    nama: "Fathan Satria",
    position: "IT Manager",
    linkedin: "https://www.linkedin.com/in/fathan-satria-anandika-06629a1a1/",
  },
  {
    id: 5,
    photo: gam,
    nama: "Elfin Prayoga",
    position: "GA Manager",
    linkedin: "https://www.linkedin.com/in/elfin-prayoga-2636a3308/",
  },
];

const leaderModal = [
  {
    id: 1,
    photo: ceo,
    nama: "Megandi",
    position: "Chief Executive Officer",
    abbreviations: "CEO",
  },
  {
    id: 2,
    photo: coo,
    nama: "Adhytia Ihza M",
    position: "Chief Operating Officer",
    abbreviations: "COO",
  },
  {
    id: 3,
    photo: dm,
    nama: "Sahrul",
    position: "Data Manager",
    abbreviations: "CTO",
  },
  {
    id: 4,
    photo: sdm,
    nama: "Fathan Satria",
    position: "Software Dev Manager",
    abbreviations: "Software Dev Manager",
  },
  {
    id: 5,
    photo: gam,
    nama: "Elfin Prayoga",
    position: "GA Manager",
    abbreviations: "GA Manager",
  },
];

export { leader, leaderModal };
