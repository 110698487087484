import React from "react";
import { motion } from "framer-motion";
import { LazyLoadImage } from "react-lazy-load-image-component";

// import data dummy
import dataProducts from "../data/dataProducts";

export default function Products(){

	const productTitleAnimate = {
		offscreen: { y: 0, opacity: 0 },
		onscreen: {
			y: 0,
			opacity: 1,
			transition: {
				type: "spring",
				bounce: 0.3,
				duration: 3,
			},
		},
	};

	const productCardsAnimate = {
		offscreen: { y: 0, opacity: 0 },
		onscreen: {
			y: 0,
			opacity: 1,
			transition: {
				type: "spring",
				bounce: 0.1,
				duration: 2,
			},
		},
	};
	
    return(
        <div className="w-100 text-white container">
            <motion.div
				className="top-responsive-products"
				id="products"
				initial="offscreen"
				whileInView="onscreen"
				viewport={{ once: true }}
				transition={{ staggerChildren: 0.1 }}
			>
                <div style={{fontFamily: 'Red Hat Display'}}>
					<div className="products-wrap">
						<motion.div variants={productTitleAnimate} className="top-products-wrap">
							<p className="frhd-bold color-yellow title-products-responsive lh-1">Our Services</p>
							<p className="mt-3 fw-bold fm-regular titleBot-products-responsive fw-bold">
								What We Do?
							</p>
						</motion.div> 
						{dataProducts.map((data) => (
							<motion.div 
								variants={productCardsAnimate}
								className="card-product" 
								key={data.id} 
								style={{position: 'relative'}}
							>
								<LazyLoadImage src={data.icon} alt="" className="content-poster-product"/>
								<p className="title-card content-title-product fw-bold position-absolute">
									{data.title}
								</p>
								<div className={`desc-card-${data.id} content-desc-product`}>	
									<div className="ms-4">
										<p className="hover-title-product fw-bold">{data.title}</p>
										<p className="hover-desc-product fw-bold pe-3"style={{fontFamily: 'Manrope'}}>
											{data.desc}
										</p>
									</div>
								</div>
							</motion.div>
						))}
						<motion.div className="position-absolute rectangle" variants={productCardsAnimate}/>
					</div>
				</div>
			</motion.div>
        </div>
    )
}