import { motion } from "framer-motion";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Link } from "react-scroll";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import BackdropModals from "./BackDropModals";
import exit from "../../assets/image/exit.png"

const fadeIn = {
    hidden: {
        y: '0',
        opacity: 0
    },
    visible: {
        y: 0,
        opacity: 1,
        transition: {
            duration: 0.1,
            type: 'spring',
            damping: 100,
            stiffness: 500
        }
    },
    exit: {
        y: 0,
        opacity: 0
    }
}

const Modals = ({handleClose}) => {
    return(
        <BackdropModals>
            <motion.div
                onClick={(e) => e.stopPropagation()} 
                className="d-flex flex-column"
                variants={fadeIn}
                initial="hidden"
                animate="visible"
                exit="exit"
                style={{ width: '100%'}}   
            >
                <div className="d-flex navbar-modal-button" style={{color: '#FFC107'}}>
                    <div className="exit-nav-modal" onClick={handleClose}>
                        <LazyLoadImage src={exit} alt=""/>
                    </div>
                </div>
                <div className="d-flex navbar-content-modal">
                    <div className="d-flex flex-column text-white nav-content-bio">
                        <div className="mb-3">
                            <h1 className="fw-bold" style={{fontFamily: 'Red Hat Display'}}>Let's Connect</h1>    
                            <p style={{fontFamily: 'Manrope'}}>office@intiva.id</p>
                        </div>
                        <div className="mb-2">
                            <div 
                                className="button-head-office fw-bold text-center mb-3" 
                                style={{backgroundColor: '#FFC107', fontFamily: 'Red Hat Display'}}
                            >
                                Head Office
                            </div>
                            <p style={{fontFamily: 'Manrope'}}>Gd. Pusat Perfilman Haji Usmar Ismail (  Gd. PARFI )
                            Lt. 2  Ruang 210 Jl. HR. Rasuna Said Kav. C22 Kuningan, Kel. Karet
                            Kuningan. Kec. Setiabudi, Jakarta Selatan 12940</p>
                        </div>
                        <div className="mb-2">
                            <div 
                                className="button-office fw-bold text-center mb-3"
                                style={{backgroundColor: '#FFC107', fontFamily: 'Red Hat Display'}}
                            >
                                Office
                            </div>
                            <p style={{fontFamily: 'Manrope'}}>Jl. Tebet Barat  Dalam Raya No 31, Tebet Barat Kecamatan Tebet, Jakarta Selatan 12810</p>
                        </div>
                        <div className="mb-2">
                            <a href="https://www.instagram.com/intiva.id/" target="blank">
                                <FontAwesomeIcon 
                                    className="mb-2" 
                                    icon={faInstagram} 
                                    style={{width: 20, height: 20, color: 'white', cursor: 'pointer'}}
                                />
                            </a>
                            <p 
                                style={{fontFamily: 'Manrope', color: 'rgba(228, 228, 228, 0.8)'}}
                            >
                                ©2021 All right reserved.<br></br>PT. Jaya Pirata Dinamika
                            </p>
                        </div>
                    </div>
                    <div 
                        className="d-flex flex-column text-white nav-content-list" 
                        style={{fontFamily: 'Red Hat Display'}}
                    >
                            <Link to="/" spy={true} duration={200} onClick={handleClose}>
                                <p className="fw-bold navbar-list" style={{cursor: 'pointer'}}>Home</p>    
                            </Link>
                            <Link to="products" spy={true} duration={200} onClick={handleClose}>
                                <p className="fw-bold navbar-list" style={{cursor: 'pointer'}}>Our Services</p>
                            </Link>
                            <Link to="project" spy={true} duration={200} onClick={handleClose}>
                                <p className="fw-bold navbar-list" style={{cursor: 'pointer'}}>Our Project</p>
                            </Link>
                            <Link to="team" spy={true} duration={200} onClick={handleClose}>
                                <p className="fw-bold navbar-list" style={{cursor: 'pointer'}}>Our Team</p>
                            </Link>
                            <Link to="contact" spy={true} duration={200} onClick={handleClose}>
                                <p className="fw-bold navbar-list" style={{cursor: 'pointer'}}>Contact Us</p>
                            </Link>
                    </div>
                </div>
            </motion.div>
        </BackdropModals>
    )
}

export default Modals