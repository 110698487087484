import Landing from "./contents/Landing";
import Products from "./contents/Products";
import Experience from "./contents/Experience";
import Team from "./contents/Team";
import TeamNewPage from "./contents/TeamNewPage";
import Contact from "./contents/Contact";
import SmoothScroll from "./utils/SmoothScroll";
import IconScroll from "./utils/IconScroll";

import "./App.css";
import "./assets/css/main.css";
// import "./assets/css/responsive.css";
import "./assets/css/responsiveGadget.css";
import "./assets/css/responsive1200px.css";
import "./assets/css/responsive1400px.css";

export default function App() {
  return (
    <>
      <SmoothScroll>
        <div className="main-bg">
          <Landing />
          <Products />
          <Experience />
          <TeamNewPage />
          <Contact />
        </div>
      </SmoothScroll>
      <div
        id="scroll-wrap"
        style={{ bottom: "45%", right: 20, position: "fixed" }}
      >
        <IconScroll />
      </div>
    </>
  );
}
