import { motion } from "framer-motion";
import { useState } from "react";
import { leader } from "../data/leader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons/faLinkedin";

export default function TeamNewPage() {
  const [linkedinActive, setLinkedidActive] = useState(false);
  const [linkedinSelected, setLinkedinSelected] = useState([
    false,
    false,
    false,
    false,
    false,
  ]);

  const handleMouseEnter = (index) => {
    const updatedHoverStates = [...linkedinSelected];
    updatedHoverStates[index] = true;
    setLinkedinSelected(updatedHoverStates);
  };

  const handleMouseLeave = (index) => {
    const updatedHoverStates = [...linkedinSelected];
    updatedHoverStates[index] = false;
    setLinkedinSelected(updatedHoverStates);
  };

  const experienceTitleAnimate = {
    offscreen: { y: 0, opacity: 0 },
    onscreen: {
      y: 0,
      opacity: 1,
      transition: {
        type: "spring",
        bounce: 0.3,
        duration: 3,
      },
    },
  };

  const productCardsAnimate = {
    offscreen: { y: 0, opacity: 0 },
    onscreen: {
      y: 0,
      opacity: 1,
      transition: {
        type: "spring",
        bounce: 0.1,
        duration: 2,
      },
    },
  };

  return (
    <div
      className="container w-100 position-relative team-bottom-responsive"
      style={{ fontFamily: "Manrope" }}
    >
      <motion.div
        className="top-team-responsive text-white"
        id="team"
        initial="offscreen"
        whileInView="onscreen"
        viewport={{ once: true }}
        transition={{ staggerChildren: 0.5 }}
      >
        <motion.div
          className="top-team-wrap"
          variants={experienceTitleAnimate}
          style={{ fontFamily: "Red Hat Display" }}
        >
          <h1 className="fw-bold">
            Our Amazing <span style={{ color: "#D69F21" }}>Co-Founder</span>
          </h1>
          {/* <div
            className="fs-7"
            style={{
              color: "rgba(255, 255, 255, 0.75)",
            }}
          >
            Welcome to the team! We are group of passionate individuals working
            together to achive our goals.
          </div>
          <span className="border-top border-bottom-solid d-inline-block color-yellow w-2rem"></span> */}
        </motion.div>
        <motion.div variants={productCardsAnimate} className="mt-5">
          <div className="text-center d-flex justify-content-center mb-5">
            <div className="d-flex flex-column">
              <img
                className="card-new-team mb-4"
                src={leader[0].photo}
                alt="ceo"
              />
              <span className="card-new-team-name">{leader[0].nama}</span>
              <span className="card-new-team-position mb-2">
                {leader[0].position}
              </span>
              <div className="d-flex justify-content-center">
                <FontAwesomeIcon
                  icon={faLinkedin}
                  style={{
                    width: 25,
                    height: 25,
                    cursor: "pointer",
                    color: linkedinActive ? "#0966C2" : "",
                  }}
                  onMouseEnter={() => {
                    setLinkedidActive(true);
                  }}
                  onMouseLeave={() => {
                    setLinkedidActive(false);
                  }}
                  onClick={() => {
                    window.open(leader[0].linkedin);
                  }}
                />
              </div>
            </div>
          </div>
          <div className="text-center d-flex justify-content-center flex-wrap gap-5">
            {leader
              .map((data, index) => (
                <div
                  className="d-flex flex-column card-new-team-container"
                  key={data.id}
                >
                  <img
                    className="card-new-team mb-4"
                    src={data.photo}
                    alt="ceo"
                  />
                  <span className="card-new-team-name">{data.nama}</span>
                  <span className="card-new-team-position mb-2">
                    {data.position}
                  </span>
                  <div className="d-flex justify-content-center">
                    <FontAwesomeIcon
                      icon={faLinkedin}
                      style={{
                        width: 25,
                        height: 25,
                        cursor: "pointer",
                        color: linkedinSelected[index] ? "#0966C2" : "",
                      }}
                      onMouseEnter={() => handleMouseEnter(index)}
                      onMouseLeave={() => handleMouseLeave(index)}
                      onClick={() => {
                        window.open(data.linkedin);
                      }}
                    />
                  </div>
                </div>
              ))
              .slice(1)}
          </div>
        </motion.div>
      </motion.div>
    </div>
  );
}
