import { motion } from "framer-motion";
import CardSliderExperience from "../components/CardSliderExperience";
import ButtonShowProject from "../components/ModalExperience/ButtonShowProject";

const experienceTitleAnimate = {
	offscreen: { y: 0, opacity: 0 },
	onscreen: {
		y: 0,
		opacity: 1,
		transition: {
			type: "spring",
			bounce: 0.3,
			duration: 3,
		},
	},
};

export default function Experience2() {
	return (
		<div
			className="w-100 position-relative experience-bottom-responsive"
			id="project"
		>
			<div className="container">
				<motion.div
					className="text-white frhd-bold top-experience-wrap"
					initial="offscreen"
					whileInView="onscreen"
					viewport={{ once: true }}
					transition={{ staggerChildren: 0.2 }}
				>
					<motion.div variants={experienceTitleAnimate}>
						<h1 className="text-center title-experience-responsive fw-bold">
							Project we are <span className="color-yellow fw-bold">proud of</span>
                        </h1>
						<p className="text-center fw-light lh-sm titleBot-experience-responsive" style={{opacity: 0.7, fontFamily: 'Manrope'}}>
                            Our software development company is truly proud of the wonderful clients we have worked with. We enjoy a long-term partnership
                        </p>
					</motion.div>
				</motion.div>

				<motion.div
					initial="offscreen"
					whileInView="onscreen"
					viewport={{ once: true }}
					transition={{ staggerChildren: 0.5 }}
				>
					<div className="showCard-experience">
						<CardSliderExperience />
					</div>
					<div className="d-flex justify-content-center">
						<ButtonShowProject/>
					</div>
				</motion.div>
			</div>
		</div>
	);
}
