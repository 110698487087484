// import socialMedia from '../assets/image/experience/SMM.png'
// import orm from '../assets/image/experience/ORM.png'
// import klh from '../assets/image/experience/KLH.png'

// import smm2 from '../assets/image/experience/SMM2.png'
// import orm2 from '../assets/image/experience/ORM2.png'
// import klh2 from '../assets/image/experience/KLH2.png'

import smm3 from "../assets/image/experience/SMM3.png"
import orp3 from "../assets/image/experience/ORP3.png"
import kln from "../assets/image/experience/KLN.png"


const experience = [
    {
        id: 1,
        photo: smm3,
        title: "Social Media Monitoring.",
        desc: "SMM is a system for measuring the success of social media marketing strategies used by companies or brands."
    },
    {
        id: 2,
        photo: orp3,
        title: "Object Recognition Project.",
        desc: "Object Recognition is a computer technology related to computer vision and image processing related to semantic object detection."
    },
    {
        id: 3,
        photo: kln,
        title: "SI Biro KLN Kementrian Lingkungan Hidup.",
        desc: "SI BIRO KLN is an official company website to provide important information to the audience about the scope of work of the  BIRO KLN and the Indonesian Ministry of Environment."
    }
]

export default experience